export function resolveSpecialText(product, onSale, t, isMobile) {

  const displayedText = [];
  const { availableForSale, tags } = product;
  const newTag = tags && tags.find((tag) => tag.toLowerCase() === "new");
  const bundle_2_15 = tags && tags.find((tag) => tag.toLowerCase() === "bundle-bedding-15" || tag.toLowerCase() === "bundle-bath-15");
  const bundle_3_15 = tags && tags.find((tag) => tag.toLowerCase() === "buy3get15");
  const bundle_5_15 = tags && tags.find((tag) => tag.toLowerCase() === "bundle-socks-15");
  const bundle_3_30 = tags && tags.find((tag) => tag.toLowerCase() === "3-for-30");
  const buy_2_get_20 = tags && tags.find((tag) => tag.toLowerCase() === "eb-24");
  const buy_3_100 = tags && tags.find((tag) => tag.toLowerCase() === "bundle-crew-3" || tag.toLowerCase() === "bundle-tanks-3");
  const buy_2_100 = tags && tags.find((tag) => tag.toLowerCase() === "bundle-heavycrew-2");
  const active_promo = tags && tags.find((tag) => tag.toLowerCase() === "denim-promo");
  const springCapsuleWardrobe =
    tags && tags.find((tag) => tag.toLowerCase() === "spring-capsule-wardrobe");

  // ARRAY OVERRIDES HERE

  if (product.tags.find((tag) => tag === "coming-soon")) {
    return [t("coming-soon")];
  }

  if (!availableForSale) {
    return [t("sold-out")];
  }

  if (springCapsuleWardrobe && !isMobile) {
    return [t("buy-2-and-save"), t("buy-3-or-more-and-save")];
  }

  if (availableForSale && newTag) { displayedText.push(t("new")); }
  if (availableForSale && bundle_2_15) { displayedText.push(t("Buy 2+, Save 15%"));}
  if (availableForSale && bundle_5_15) { displayedText.push(t("Buy 5+, Save 15%")); }
  if (availableForSale && bundle_3_15) { displayedText.push(t("Buy 3+, Get 15% Off")); }
  if (availableForSale && buy_3_100) { displayedText.push(t("Buy 3 for $100")); }
  if (availableForSale && buy_2_100) { displayedText.push(t("Buy 2 for $100")); }
  // if (availableForSale && active_promo) { displayedText.push(t("Extra 10% off")); }
  if (availableForSale && onSale) { displayedText.push(t("Sale")); } 

  displayedText.push(t("Extra 10% off"));
  return displayedText; 
}
